$baseColor: #00aeee;
$baseColorHover: #000000;

$baseColorTitle: #00aeee;
$baseColorTint: #000000;

/*
 * Form elements
 */

$formelements-font-size-base: 14px;
$formelements-line-height-base: 14px;
$formelements-color: #000;
$formelements-border: transparent;
$formelements-placeholder: #000;
$formelements-padding: 12px 40px 12px 12px;
$formelements-background: #ededf0;
$formelements-radius: 5px;
$formelements-danger: $baseColor;
$formelements-success: $baseColor;

$ctaLink: #000;
$ctaBgLink: #c6eeff;

$ctaButtonColorPrimary: #fff;
$ctaButtonColorHoverPrimary: #fff;
$ctaButtonBgColorPrimary: #00aeee;
$ctaButtonBgColorHoverPrimary: #000000;

$ctaButtonColorSecondary: #00aeee;
$ctaButtonColorHoverSecondary: #fff;
$ctaButtonBgColorSecondary: #fff;
$ctaButtonBgColorHoverSecondary: #000000;

$font: "museo-sans", sans-serif;

$white: #fff;
$black: #000;
$grey: #f3f3f3;

$animation-default-speed: 0.2s;
$baseline: 8px;
